.App {
  width: 100%;
  height: 100%;
  .sample-demo {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }
  .chat-demo {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: #f9fafb;
  }
  .chat-main {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
  }
}
.chat-header {
  position: relative;
  background: #f9fafb;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 60px;
  width: 100%;
  justify-content: end;
  box-sizing: border-box;
  .language-container {
    position: relative;
    width: 100px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    z-index: 1;
    .language-icon {
      margin-right: 10px;
    }
    .title-container {
      display: flex;
      align-items: center;
    }
    .language-title {
      padding-right: 4px;
      color: #000;
      line-height: 25px;
    }
    .language-text {
      font-size: 14px;
      color: #666666;
      line-height: 25px;

    }
    .language-item-container {
      position: absolute;
      transform: translateY(100%);
      width: 100px;
      padding: 10px 0;
      background: #f9fafb;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 0;
      border-radius: 10px;
    }
  }
}

.tui-contact-search .tui-contact-search-item {
  position: relative;
}